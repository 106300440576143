.tooltipWrapper {
  display: flex;
  flex-direction: column;
  /* max-width: 200px; */
  box-shadow: 2px 2px 6px -4px #999;
}

.tooltipHeader {
  background-color: #eceff1;
  padding: 8px;
  border: 2px solid #e3e3e3;
  border-bottom: 2px solid #ddd;
  border-radius: 9px 9px 0 0;
}

.tooltipTitle {
  font-size: 14px;
  color: #333333;
}

.tooltipBody {
  background-color: #ffffff;
  padding: 8px;
  border: 2px solid #E3E3E3;
  border-top: 0;
  border-radius: 0 0 9px 9px;
}

.tooltipContent {
  display: flex;
  flex-direction: column;
}

.tooltipContent .row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  margin: 4px 0;
  gap: 7px;
}

/* .tooltipContent .row :first-child {
  width: 45%;
  margin-right: 20px;
}

.tooltipContent .row :last-child {
  /* padding-left: 10px; */
  /* width: 50%; */
/* } */

.tooltipContentItem {
  margin: 4px 0;
  font-size: 12px;
  color: #666666;
}

.separator {
  height: 1px;
  background-color: #ccc;
  margin: 5px 0;
}

.tooltipContent .column:nth-child(2){
  content: ' ';
  width: 1px;
  height: 80px;
  background-color: #ccc;
}

.verticalSeparator {
  height: 100%;
  width: 1px;
  /* background-color: black; */
}
