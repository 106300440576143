.tiptap {
  .ProseMirror {
    border: 1px solid #ced4da;
    border-radius: 6px;
    padding: 1rem;
    margin-top: 1rem;
    min-height: 600px;
    height: calc(100vh - 530px);
    max-height: calc(100vh - 530px);
    overflow-y: auto;
    &:focus-visible {
      outline: none;
    }
  }

  .menubar {
    // border-bottom: 1px solid #ced4da;
    display: flex;
    // padding: 0.5rem 1rem;
    gap: 0.5rem;
    flex-wrap: wrap;
    button {
      background-color: #f1f3f5;
      border: none;
      border-radius: 0.25rem;
      color: #0d0d0d;
      cursor: pointer;
      font-size: 1rem;
      // padding: 0.5rem;
      transition: background-color 0.2s;
      // &:hover {
      //   background-color: #e9ecef;
      // }
      span {
        margin: 0;
      }
    }

    input[type="color"] {
      background-color: #f1f3f5;
      border: none;
      border-radius: 0.25rem;
      color: #0d0d0d;
      font-size: 1rem;
    }
    .is-active {
      background-color: #0d0d0d;
      color: #f1f3f5;
      // &:hover {
      //   background-color: #e9ecef;
      //   color: #0d0d0d;
      // }
    }
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0d0d0d;
    color: #fff;
    font-family: "JetBrainsMono", monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0d0d0d, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0d0d0d, 0.1);
    margin: 2rem 0;
  }
  table {
    border-collapse: collapse;
    margin: 0;
    overflow: hidden;
    table-layout: fixed;
    width: 100%;

    td,
    th {
      border: 2px solid #ced4da;
      box-sizing: border-box;
      min-width: 1em;
      padding: 3px 5px;
      position: relative;
      vertical-align: top;

      > * {
        margin-bottom: 0;
      }
    }

    th {
      background-color: #f1f3f5;
      font-weight: bold;
      text-align: left;
    }

    .selectedCell:after {
      background: rgba(200, 200, 255, 0.4);
      content: "";
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      pointer-events: none;
      position: absolute;
      z-index: 2;
    }

    .column-resize-handle {
      background-color: #adf;
      bottom: -2px;
      position: absolute;
      right: -2px;
      pointer-events: none;
      top: 0;
      width: 4px;
    }

    p {
      margin: 0;
    }
  }
  p {
    margin: 0;
  }
}
